import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  initialize() {
    this.show = this.show.bind(this);
  }

  enableAutoRefresh() {
    this.refreshTimer = setInterval(this.show, 1000);
  }

  disableAutoRefresh() {
    clearInterval(this.refreshTimer);
  }

  show() {
    this.stimulate("BankId::QrCodes#show");
  }
}
