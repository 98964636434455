import ApplicationController from "./application_controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["errors", "modal"];

  connect() {
    super.connect();
    this.modal = new Modal(this.modalTarget);
  }

  disconnect() {
    this.modal.dispose();
  }

  index(event) {
    let page = 1;

    if (event) {
      page = Object(event.currentTarget.dataset).licensePlatePage;
    }

    this.stimulate(`${this.reflexClass}#index`, { serializeForm: true }, page);
  }

  new() {
    this.modalTarget.firstElementChild.innerHTML = "";
    this.stimulate(`${this.reflexClass}#new`);
  }

  finalizeNew() {
    this.modal.show();
  }

  create() {
    this.stimulate(`${this.reflexClass}#create`, { serializeForm: true });
  }

  finalizeCreate() {
    if (!this.errorsTarget.children.length) {
      this.modalTarget.addEventListener(
        "hidden.bs.modal",
        this.index.bind(this),
        { once: true }
      );

      this.modal.hide();
    }
  }

  edit(event) {
    this.modalTarget.firstElementChild.innerHTML = "";
    this.stimulate(`${this.reflexClass}#edit`, event.currentTarget.dataset.id);
  }

  finalizeEdit() {
    this.modal.show();
  }

  update(event) {
    this.stimulate(
      `${this.reflexClass}#update`,
      { serializeForm: true },
      event.currentTarget.dataset.id
    );
  }

  finalizeUpdate() {
    if (!this.errorsTarget.children.length) {
      this.modalTarget.addEventListener(
        "hidden.bs.modal",
        this.index.bind(this),
        { once: true }
      );

      this.modal.hide();
    }
  }

  destroy(event) {
    if (confirm(event.currentTarget.dataset.message)) {
      this.stimulate(
        `${this.reflexClass}#destroy`,
        event.currentTarget.dataset.id
      );
    }
  }

  afterDestroy() {
    this.index();
  }
}
