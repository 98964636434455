import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  static outlets = ["bank-id--qr-codes"];

  beforeCreate() {
    this.bankIdQrCodesOutlet.disableAutoRefresh();
  }

  create() {
    this.
      stimulate("BankId::AutoStarts#create").
      then(({ payload }) => window.location.href = payload.autoStartLink);
  }
}
