import ApplicationController from "./application_controller";
import Inputmask from "inputmask";

Inputmask.extendAliases({
  accountNumber: {
    mask: "9{10}"
  },
  bankgiroNumber: {
    mask: "9{8}"
  },
  color: {
    mask: "\\##{6}",
    casing: "lower"
  },
  clearingNumber: {
    mask: "9{4}[-9]"
  },
  currencyAmount: {
    regex: String.raw`\d{1,5}([.]\d{0,2})?`
  },
  gln: {
    mask: "9{13}"
  },
  eligibilityCode: {
    regex: String.raw`[\w-]{0,30}`,
    casing: "upper"
  },
  licensePlate: {
    regex: String.raw`[\w\såäö]{2,10}`,
    casing: "upper"
  },
  organizationNumber: {
    mask: "9{12}"
  },
  percent: {
    regex: String.raw`\d{1,3}([.]\d{0,3})?`
  },
  personalNumber: {
    mask: "9{12}"
  },
  phone: {
    regex: String.raw`[+]?\d{14}`
  },
  plusGiroNumber: {
    mask: "9{8}"
  },
  zip: {
    mask: "999 99"
  }
});

export default class extends ApplicationController {
  connect() {
    Inputmask({
      placeholder: "",
      removeMaskOnSubmit: true,
      showMaskOnHover: false,
      showMaskOnFocus: false,
      jitMasking: true
    }).mask(this.element);
  }

  disconnect() {
    Inputmask.remove(this.element);
  }
}
