import ApplicationController from "../application_controller";

export default class extends ApplicationController {
  index(event) {
    this.stimulate(
      "Admin::Spots#index",
      { serializeForm: true },
      Number(event.currentTarget.dataset.page) || 1
    );
  }
}
